/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Bem } from 'react-bem-classes';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';
import './index.scss';
import PropTypes from 'prop-types';

const bem = new Bem('footer');

const connectComponent = connect(state => ({
  allCmsKeys: allCMSKeysRawSelector(state),
}));

class PageFooter extends React.Component {
  static contextTypes = { showCookieDialog: PropTypes.func.isRequired };

  onClickFooter = () => {
    if (window.Cookiebot) {
      window.Cookiebot.show();
    }
  };

  render() {
    const { allCmsKeys } = this.props;

    const footerBackground = allCmsKeys.PXP_FOOTER_LOGO;
    const cookieDisclaimer = allCmsKeys.COOKIES_POLICY_CONSENT_TITLE;
    // This key will be specified if Klarna payment
    // should contain this url https://cdn.klarna.com/1.0/shared/image/generic/logo/se_se/basic/logo_black.png
    const klarnaLogotypeIfEligible = allCmsKeys.KLARNA_FOOTER_LOGO_URL;

    return (
      <div className={bem.block()}>
        <div className="container">
          <div className="footer-content">
            <div className="row footer-logos">
              {/* Row */}
              <div className="col-xs-12 col-sm-6 secure-payment-container">
                <div className="secure-payment clearfix">
                  <i className="icon shield2 pull-left" />
                  <div className="secure-payment-text">SSL Secure Payments</div>
                  <div className="secure-payment-icons">
                    <i className="icon visa" />
                    &nbsp;
                    <i className="icon mastercard" />
                    {klarnaLogotypeIfEligible && (
                      <>
                        &nbsp;
                        <img
                          className={bem.element('klarnaLogo')}
                          src={klarnaLogotypeIfEligible}
                          alt="Klarna logotype"
                          role="presentation"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 powered-by-content">
                <div
                  className="powered-by pull-right"
                  style={{
                    backgroundImage: `url(${footerBackground})`,
                  }}
                />
              </div>
            </div>
            {/* Row */}
            <div className="row cookie-disclaimer">
              <button
                type="button"
                onClick={this.onClickFooter}
                alt={cookieDisclaimer}
              >
                {cookieDisclaimer}
              </button>
            </div>
            <div
              className="powered-by powered-by-mobile"
              style={{
                backgroundImage: `url(${footerBackground})`,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connectComponent(PageFooter);
